import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import {
  useUserIdentifyStore,
  useVerificationModalStore,
} from '../../model';

export const userVerificationGuard = (text: string) => (
  to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext,
) => {
  const userIdentityStore = useUserIdentifyStore();

  if (!userIdentityStore.isUserVerified) {
    const verificationModal = useVerificationModalStore();
    verificationModal.isOpen = true;
    verificationModal.text = text;
    return next(from);
  }

  return next(to);
};
