import { AxiosError } from 'axios';
import { arrayLib } from '@fsd/shared/lib';
import { MAX_ERROR_LENGTH } from './consts';
import { ValidationErrorTree, FlattenedErrors, CustomAxiosError } from './types';

const { isArray } = arrayLib;

export const flattenValidationErrorTree = (errorTree: ValidationErrorTree): FlattenedErrors => {
  const result: FlattenedErrors = {};

  const traverse = (tree: ValidationErrorTree) => {
    Object.entries(tree).forEach(([key, value]) => {
      if (isArray(value)) {
        result[key] = value;
      } else if (typeof value === 'object' && value !== null) {
        traverse(value as ValidationErrorTree);
      }
    });
  };

  traverse(errorTree);
  return result;
};

export const getPreparedError = (error: CustomAxiosError) => {
  const preparedError = error?.response?.data?.error
    || error?.response?.data?.errorsTree
    || error?.response?.data?.errorDescription
    || error.message;

  if (preparedError) return preparedError;
  return (typeof error === 'object' ? JSON.stringify(error) : error).slice(0, MAX_ERROR_LENGTH);
};

export const buildResponse = (
  { statusCode, msg }: {
    statusCode?: number;
    msg: string;
  },
  errorMessageMapper: (msg: string) => string,
) => ({
  statusCode,
  msg: errorMessageMapper(msg),
});

export const parseValidationError = (
  error: CustomAxiosError<{ errors: ValidationErrorTree }>,
): unknown => {
  const errorsTree = error.response?.data?.errors;

  if (!errorsTree) {
    return undefined;
  }

  const flattenedErrors = flattenValidationErrorTree(errorsTree);
  return flattenedErrors;
};

export const parseBusinessLogicError = (
  error: CustomAxiosError<{ errors?: string; errorsTree?: string }>,
): string | undefined => {
  const errorMsg = error.response?.data?.errors || error.response?.data?.errorsTree;
  return errorMsg ? String(errorMsg) : undefined;
};

export const parseOauthValidationError = (e: AxiosError): string => e.response?.data?.errorDescription;
