import { AxiosError } from 'axios';
import { CustomAxiosRequestConfig } from '@fsd/shared/api/http';
import { errorTracker, logger, httpErrorParser } from '@fsd/shared/lib';
import { useLocale } from '@fsd/shared/locale';
import { isAxiosError, showErrorMsg, getErrorMsg } from './lib';

export interface ErrorHandlerOptions {
  skipErrorByOneOfCodes?: number[];
  multipleNotify?: boolean;
  showAllDefaultErrorMessageExtended?: boolean;
  errorMessageMapper?: (message: string) => string;
  description?: string;
  isCreateExtendedDefaultErrorMessage?: boolean;
  defaultErrorMessage?: string;
  msgByStatusCode?: Record<number, string>;
  parsersByStatusCode?: Record<number, (error: AxiosError) => string | undefined>;
  $t?: (msg: string) => string;
}

export const errorHandler = async (
  error: unknown,
  customParser?: (error: AxiosError) => string | undefined,
  options: ErrorHandlerOptions = {
    $t: useLocale().$t,
  },
): Promise<never> => {
  const errorHandlerOptions = isAxiosError(error)
    ? (error.config as CustomAxiosRequestConfig)?.errorHandlerOptions || {}
    : {};

  const finalOptions: ErrorHandlerOptions = { ...options, ...errorHandlerOptions };

  const {
    skipErrorByOneOfCodes = [422],
    showAllDefaultErrorMessageExtended = false,
    multipleNotify = false,
    description,
  } = finalOptions;

  try {
    if (isAxiosError(error)) {
      const { msg, statusCode } = httpErrorParser.parse(error, customParser, finalOptions);

      const shouldSkipError = statusCode && skipErrorByOneOfCodes.includes(statusCode);

      if (!shouldSkipError) {
        showErrorMsg(msg, multipleNotify);
      }
    }
  } catch (parsingError: unknown) {
    const errorMessage = getErrorMsg(
      { error: parsingError, description },
      showAllDefaultErrorMessageExtended,
    );

    showErrorMsg(errorMessage);
    errorTracker.track(parsingError);
    logger.logError(parsingError, description);
  } finally {
    errorTracker.track(error);
    logger.logError(error, description);
  }

  return Promise.reject(error);
};
