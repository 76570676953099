import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { DOCUMENT_STATUSES, UserIdentify } from '../../types';

export const useUserIdentifyStore = defineStore('user-identify', () => {
  const userIdentity = ref<UserIdentify>();

  const isUserVerified = computed<boolean>(() => {
    const { phoneNumberVerified, emailVerified } = userIdentity.value!;
    const isDocumentVerified = userIdentity.value!.documents[0]?.status === DOCUMENT_STATUSES.ACCEPTED;

    return phoneNumberVerified && emailVerified && isDocumentVerified;
  });

  return {
    userIdentity,
    isUserVerified,
  };
});
