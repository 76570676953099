import { parseAccessToken } from '@amarkets/atlas/esm/lib/parse-access-token';
import { initIntercom } from '@/plugins/intercom';
import { LOCALES, ROUTES } from '@/constants';
import { initRefiner } from './init-refiner';
import {
  setUserRoles,
  defineSsoConfig,
  fetchUserData,
  fetchUserProfile,
  setUserDataFromIdToken,
  setUserIp,
  updateLocale,
} from './events';
import { createCtx } from './factories/create-ctx';
import { mountApp } from './mount-app';
import { setToLoaded, setToError } from './init-state-machine';

export async function initApp() {
  try {
    // // TODO: finalize and bring the logic to the atlas
    const isProductionEnv = import.meta.env.VITE_ENV === 'production';
    const authSSODomain = await defineSsoConfig({ origin: window.location.origin });
    const ctx = await createCtx(authSSODomain);

    // display app in loading status
    mountApp(ctx);

    if (!ctx.jwtService.accessToken) {
      const { accessToken, refreshToken, idToken } = await ctx.ssoClient.authorize();

      ctx.jwtService.setTokens({ accessToken, refreshToken, idToken });
    }

    const { accessToken, idToken } = ctx.jwtService;

    const { userId, userRoles } = parseAccessToken(accessToken);

    setUserRoles({ userRoles, store: ctx.store });

    await fetchUserData({ userId, store: ctx.store });

    if (idToken) {
      setUserDataFromIdToken({ idToken, store: ctx.store });
    }

    await updateLocale({ store: ctx.store });
    setUserIp({ userIp: authSSODomain.CLIENT_IP, store: ctx.store });

    ctx.centrifuge.init(ctx.jwtService, ctx.ssoClient);

    const userProfile = await fetchUserProfile({ store: ctx.store });

    if (userProfile.officeTotalLocked) {
      ctx.router.push({ name: ROUTES.USER_BLOCKED.NAME });
    }

    ctx.vuetify.rtl = userProfile.locale === LOCALES.FA;
    ctx.vuetify.locale.current = userProfile.locale;

    if (isProductionEnv) {
      initRefiner({ userUid: userId, userProfile });
      window.dataLayer?.push({ event: 'userId', user_id: userId });
    }

    if (import.meta.env.VITE_ENV !== 'development') {
      initIntercom(import.meta.env.VITE_INTERCOM_APP_ID, {
        id: userId,
        name: userProfile.fullName,
        email: userProfile.email,
        created_at: new Date(userProfile.createdAt).getTime(),
      });
    }

    // display app
    setToLoaded();
  } catch (e) {
    // display app in error status
    setToError(e);
    console.error(e);
    throw (e);
  }
}
