<script setup lang="ts">
import { computed } from 'vue';
import { SIZES } from '@/fsd/shared/constants';
import { AIcon, AText } from '@fsd/shared/ui';
import { ICONS } from '@fsd/shared/ui/a-icon';
import { CLASSES_BY_SIZE, type ChipSizes } from '../const';

defineOptions({
  name: 'a-chip',
});

const props = withDefaults(defineProps<{
  size?: ChipSizes,
  icon?: typeof ICONS[keyof typeof ICONS],
  disabled?: boolean,
}>(), {
  size: SIZES.M,
});

const selected = defineModel<boolean>();

const classes = computed(() => ([
  CLASSES_BY_SIZE[props.size],
  selected.value ? '!border-controls-primary-active' : '!border-stroke-primary',
  props.disabled ? 'pointer-events-none bg-controls-inactive opacity-50' : '',
]));

const fontSize = computed(() => ({ 'text-xs': props.size === SIZES.S }));

const toggleSelect = () => {
  selected.value = !selected.value;
};
</script>

<template>
  <div
    data-name="a-chip"
    class="inline-block"
  >
    <div
      tabindex="0"
      class="inline-flex cursor-pointer items-center !rounded-full border hover:bg-transparent-gray-10
      focus-visible:bg-transparent-gray-10 focus-visible:outline focus-visible:outline-transparent-active"
      :class="classes"
      @click="toggleSelect"
    >
      <a-icon
        v-if="icon"
        class="me-1 text-icons-tertiary"
        :class="fontSize"
        :name="icon"
      />
      <a-text
        weight="semibold"
        class=" text-text-primary"
        :class="fontSize"
      >
        <slot />
      </a-text>
    </div>
  </div>
</template>
