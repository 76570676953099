import { RouteRecordRaw } from 'vue-router';
import { SETTINGS_ROUTES } from '@fsd/shared/constants/routes';

export const ChangeAccountType: RouteRecordRaw[] = [
  {
    name: SETTINGS_ROUTES.CHANGE_ACCOUNT_TYPE.NAME,
    path: SETTINGS_ROUTES.CHANGE_ACCOUNT_TYPE.PATH,
    component: () => import('./page-change-account-type.vue'),
    meta: {
      title: 'pageTitle.changeAccountType',
    },
  },
];
