import { Action } from '@amarkets/atlas/esm/lib/action';
import { useUserIdentifyStore } from '@fsd/entities/user-identify';
import { User } from '../entities/user';
import { UserMapper } from '../mappers/user-mapper';
import {
  MUTATION_SET_USER_DATA,
  MUTATION_SET_USER_DATA_LOADING,
} from '../types';

export class FetchUserData extends Action {
  _api;

  constructor({ identifyApi }) {
    super();
    this._api = identifyApi;
  }

  async execute({ commit }, { userId }) {
    await FetchUserData._withLoading(async () => {
      try {
        const userMapper = new UserMapper((opts) => new User(opts));
        const userData = await this._api.getUserById(
          { userId }, (data) => userMapper.mapUser(data),
        );

        commit(MUTATION_SET_USER_DATA, userData);

        const userIdentifyStore = useUserIdentifyStore();
        userIdentifyStore.userIdentity = userData;
      } catch (e) {
        console.error(e);
      }
    }, commit, MUTATION_SET_USER_DATA_LOADING);
  }
}
