import { RouteRecordRaw } from 'vue-router';
import { FINANCE_ROUTES } from '@fsd/shared/constants/routes';

export const FinanceRoutes: RouteRecordRaw[] = [
  {
    name: FINANCE_ROUTES.FINANCE_WITHDRAW.NAME,
    path: FINANCE_ROUTES.FINANCE_WITHDRAW.PATH,
    component: () => import('@fsd/pages/finance/withdraw/index.vue'),
    meta: { title: 'withdrawPage.title' },
  },
  {
    name: FINANCE_ROUTES.FINANCE_WITHDRAW_REQUEST_CREATED.NAME,
    path: FINANCE_ROUTES.FINANCE_WITHDRAW_REQUEST_CREATED.PATH,
    component: () => import('@fsd/pages/finance/withdraw/request-created/index.vue'),
    meta: {
      title: 'withdrawPage.title',
    },
  },
  {
    name: FINANCE_ROUTES.CREATE_PAYMENT_METHOD.NAME,
    path: FINANCE_ROUTES.CREATE_PAYMENT_METHOD.PATH,
    component: () => import('@fsd/pages/finance/create-payment-method/index.vue'),
    meta: { title: 'pageFinance.makeWithdrawal.title' },
  },
];
