import { useLoading } from '@fsd/shared/lib';
import { useHttpClient } from '../../http/index';
import type {
  AvailableChangeGroupResponse,
  StatusRequestResponse,
  ChangeAccountGroupRequest,
  Requested,
} from './types';

const { get, post, delete: remove } = useHttpClient();

export const useChangeAccountGroupApi = () => {
  const { isLoading, withLoading } = useLoading();

  const fetchAvailableChangeGroup = async () => withLoading(async () => {
    const { data } = await get<AvailableChangeGroupResponse>('/v1/accounts/trading/available_for_change_group');

    return data;
  });

  const getStatusRequestByUid = async (accountUid: string) => {
    const { data } = await get<StatusRequestResponse>(`/v1/accounts/trading/${accountUid}/change_account_group_requests`);

    return data;
  };

  const changeAccountGroup = async ({
    accountUid,
    requestedGroupId,
  }: {
    accountUid: string,
    requestedGroupId: string,
  }) => {
    const payload = {
      data: {
        type: 'change_account_group_requests',
        attributes: {
          requestedGroupId,
        },
      } as ChangeAccountGroupRequest,
    };

    const { data } = await post<{ data: Requested }>(`/v1/accounts/trading/${accountUid}/change_account_group_requests`, payload);

    return data;
  };

  const removeChangeGroupRequest = async (requestedGroupId: string) => {
    const { data } = await remove<{ data: Requested }>(`/v1/accounts/change_account_group_requests/${requestedGroupId}`);

    return data;
  };

  return {
    fetchAvailableChangeGroup,
    getStatusRequestByUid,
    changeAccountGroup,
    removeChangeGroupRequest,
    isLoading,
  };
};
