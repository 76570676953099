import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVerificationModalStore = defineStore('verification-modal', () => {
  const isOpen = ref<boolean>(false);
  const text = ref<string>('');

  return {
    isOpen,
    text,
  };
});
