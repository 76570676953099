import { SIZES } from '@fsd/shared/constants';

export type SpinnerSizes = 's' | 'm' | 'l' | 'xl';

export const SIZE_MAP = {
  [SIZES.S]: 6,
  [SIZES.M]: 6,
  [SIZES.L]: 8,
  [SIZES.XL]: 10,
} as const;

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
} as const;
