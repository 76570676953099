import { RouteRecordRaw } from 'vue-router';
import { userVerificationGuard } from '@fsd/entities/user-identify';
import { COPY_TRADING_ROUTES } from '@fsd/shared/constants/routes';

export const CopyTradingRoutes: RouteRecordRaw[] = [
  {
    name: COPY_TRADING_ROUTES.COPY_TRADING.NAME,
    path: COPY_TRADING_ROUTES.COPY_TRADING.PATH,
    component: () => import('./page-copy-trading.vue'),
    meta: {
      title: 'pageTitle.copyTrading',
    },
    beforeEnter: (to, from, next) => userVerificationGuard('copyTrading.verificationModal.text')(to, from, next),
  },
];
