import { useHttpClient } from '../../http/index';
import {
  GetTradingAccountsResponse,
  GetAccountsLeveragesResponse,
  PostAccountLeverageResponse,
} from './types';

const { get, post } = useHttpClient();

export const useAccountApi = () => {
  const getTradingAccounts = async () => {
    const { data } = await get<GetTradingAccountsResponse>('/v1/accounts/trading');
    return { data };
  };

  const getAccountsAvailableLeverages = async () => {
    const { data } = await get<GetAccountsLeveragesResponse>('v1/accounts/trading/available_for_change_leverage');

    return data;
  };

  const postAccountLeverage = async (
    accountUid: string,
    requestedLeverage: number,
  ) => {
    const data = await post<PostAccountLeverageResponse>(
      `v1/accounts/trading/${accountUid}/change_leverage_requests`,
      {
        data: {
          type: 'change_leverage_requests',
          attributes: {
            requested_leverage: requestedLeverage,
          },
        },
      },
      {
        errorHandlerOptions: {
          skipErrorByOneOfCodes: [403, 422],
        },
      },
    );

    return data;
  };

  return {
    getTradingAccounts,
    getAccountsAvailableLeverages,
    postAccountLeverage,
  };
};
